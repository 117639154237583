* {
 -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

html {
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-default;
  font-weight: $font-regular;
  font-size: $font-size-default-px;
  line-height: $line-height-default;
  height: 100%;
  color: $text-gray;
  min-height: -webkit-fill-available;
}

*:focus {
    outline: none;
}

button:focus {outline:0;}

#root, #root > div {
  height: 100%;
}

ul, ol, dl {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: $primary-color;
}

label {
  margin-bottom: 0;
}

.hideText {
  overflow: hidden;
  text-indent: -9999px;
  display: inline-block;
}

.relative {
  position: relative;
}

.fw-semibold, .semibold {
  font-weight: $font-semibold!important;
}

.fw-medium {
  font-weight: $font-medium!important;
}

.flex {
  @include flexbox();
}

.color-danger {
  color: $danger-color;
}

.d-xxxxs-none {
  @include media-below(xxxxs) {
    display: none!important;
  }
}

.color-primary {
  color: $primary-color!important;
}

// Headings, paragraphs - font sizes

h1, h2, h3, h4 {
  font-weight: $font-bold;
  margin: 0;
  padding: 0;
  color: $gray-dark;

  [class*="loader"] {
    margin-left: 15px;
  }
}

h1 {
  @include h2();
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}

h4 {
  @include h4();
}

p {
  margin-bottom: 0;
}

strong {
  font-weight: $font-semibold;
}

dl {
  dt {
    font-weight: $font-regular;
  }

  dd {
    margin-bottom: 0;
  }
}

header {
  nav {
    &.navbar-collapse {
      @include media-below(md) {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

  	&.collapse {
      display: flex;
      @include mobile-navigation-fixed();
  	}
  }
}

// == PRINT

.print-page-break {
  page-break-after: always;
}

// == MOBILE OVERLAY PANELS

@include media-below(md) {
  .bodyScrollOff { // disable body scroll
  	height: 100vh;
  	overflow: hidden;
  }

  .main-userNavigation, .header-userNavigation, .footer-userNavigation footer { display: none!important; }

  .main-mainNavigation, .header-mainNavigation, .footer-mainNavigation { display: none!important; }

  .header-profileNavigation { z-index: 10; }
  .main-profileNavigation { }
 .footer-profileNavigation footer { display: none!important; }
}
