.container {
	max-width: 1360px;

	&.narrow {
		max-width: 1240px;
	}
}

.px-lg-15 {
	@include media-above(md) {
		padding-left: 15px!important;
		padding-right: 15px!important;
	}

	@include media-above(lg) {
		padding-left: 0!important;
		padding-right: 0!important;
	}
}

.mx-lg-15 {
	@include media-above(md) {
		margin-left: -15px!important;
		margin-right: -15px!important;
	}

	@include media-above(lg) {
		margin-left: 0!important;
		margin-right: 0!important;
	}
}

.px-20 {
	padding-left: 20px;
	padding-right: 20px;
}

.col-detail-lg-7 {
	@media (min-width: 996px) {
		max-width: 58%;
	}

	@media (min-width: 1025px) {
		max-width: 60%;
	}
}
.detail-aside-lg {
	@media (min-width: 1025px) {
		margin-left: 70px;
	}
}
