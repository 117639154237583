.callout {
	width: 100%;
	padding: 20px;
	text-align: center;
	@include border-radius(5px);

	a {
		color: inherit;
		text-decoration: underline;
	}

	&.compact {
		padding: 10px 20px;
	}

	&:not(.outline) {
		background-color: $background-gray;

		&.danger {
			background-color: $danger-color;
			color: $white;
		}

		&.success {
			background-color: $success-color;
			color: $white;
		}
	}

	&.outline {
		border: 1px solid transparent;
		padding: 15px;

		&.danger {
			border-color: $danger-color;
			color: $danger-color;
		}

		&.success {
			border-color: $success-color;
			color: $success-color;
		}
	}

	i {
		margin-right: 10px;
	}
}
